<template>
  <section class="agent--partner-wrapper">
    <div class="title--text">
      {{ $t('helpDesk.developerPartner.title') }}
    </div>
    <!--    <div class="alert alert-warning" role="alert">-->
    <!--      Mencari properti sesuai dengan kebutuhan perusahaan anda kini menjadi lebih mudah, aman, dan-->
    <!--      cepat. Rentfix hadir untuk memberikan jasa pencarian properti terbaik. Daftar untuk mencari-->
    <!--      properti yang perusahaan Anda butuhkan.-->
    <!--    </div>-->
    <developer-partner-form />
  </section>
</template>

<script>
import DeveloperPartnerForm from '@/components/help-desk/developer-partner/developer-partner-form';

export default {
  components: {
    DeveloperPartnerForm,
  },
};
</script>
